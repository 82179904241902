import { IPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

export interface OccupyDetail {
    createTime?: string   // [2022, 3, 3, 9, 39, 34]
    id: string   // "01097699e65147e8bc58b3fd54bc5ac4"
    materialCode?: string   // "12JS160T-1701170"
    materialName?: string   // "一二档同步器总成"
    num?: number   // 1
    pageNum?: string   // null
    pageSize?: string   // null
    price?: string   // 789.45
    shopId?: string   // "18059196cb7c4829ac35f23329ba2256"
    status?: string   // "purchase.allocation"
    type?: string   // "io_spd"
    warehouseCode?: string   // "WH-M-00000012"
    warehouseName?: string   // "天津陕汽库"
}

class OccupyService {
    async scmOccupy(searchVo: any) {
        const apiUrl = "/scm2/statistics/purchaseSaleStorageDeatil";
        return await httpRequest.post<OccupyDetail[]>(apiUrl, searchVo);
    }

    async bcmOccupy(searchVo: any) {
        const apiUrl = `/bcm/occupy/findPage`;
        return await httpRequest.post<IPage<OccupyDetail[]>>(apiUrl, searchVo);
    }

    async vrmOccupy(searchVo: any) {
        const apiUrl = `/vrm2/statistics/purchaseSaleStorageDeatil`;
        return await httpRequest.post<OccupyDetail[]>(apiUrl, searchVo);
    }

    async ciOccupy(searchVo: any) {
        const baseURL = getBaseCiRequestUrl();
        const apiUrl = `/ci-storage/statistics/purchaseSaleStorageDeatil`;
        return await httpRequest.post<OccupyDetail[]>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }

    //根据shopid查询云创机构
    async getByShopId(shopId: string) {
        const baseURL = getBaseCiRequestUrl();
        const apiUrl = `/ci-platform/organ/getByShopId/${shopId}`;
        return await httpRequest.get<any>(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
}

export const occupyService = new OccupyService()
