import { useEffect, useRef, useState } from 'react'
import { createModel } from 'hox'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { IDetail, ISearchFields, Istatistics, service } from './service'
import useGlobalModel, { transPgToPagination } from '@/model/globalModel'
import { ISearchPage } from '@/types/AppTypes'
import { convertRes2Blob } from '@/utils/exportFile'
import { message } from 'antd'

export const useModel = createModel(function() {
    const {
        user: { shopId, branch },
        setLoadingInfo
    } = useGlobalModel()
    /**
     * 列表
     */
        //列表数据源
    const [dataSource, setDataSource] = useState<IDetail[]>([])
    const [tableTotal, setTableTotal] = useState<Istatistics>({})

    //查询条件
    const [searchParam, setSearchParam] = useState<ISearchFields>({})
    const orders = useRef<{ column: string; asc: boolean }[]>([])
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg)
    const pgCfgRef = useRef<TablePaginationConfig>(initPgCfg)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        pgCfgRef.current = pgCfg
    }, [pgCfg])

    //列表查询
    const loadData = async (page: ISearchPage = pgCfgRef.current, searchValue: ISearchFields = searchParam) => {
        setLoading(true)

        const searchParams = {
            shopId,
            branch,
            ...searchValue,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? DEFAULT_FIRST_PAGE_NUM,
            orders: orders.current
        }
        if (branch === 'ci') {
            searchParams.searchItem = {
                shopId,
                branch,
                ...searchValue, ...{ purchaseCode: searchValue.bussCode }
            }
        }
        let { retData } = await service.page(searchParams)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        records.forEach((item, index) => {
            item.index = index
            item.availableNum = +(item.inventoryQuantity || 0) - +(item.occupyNum || 0) + ''
            if (branch !== 'scm') {
                item.stockIoNum = item.num
                item.costPrice = item.latestPurPrice
                item.stockIoAmount = item.amount

                item.inventoryQuantity = item.inventoryNum
                item.occupyNum = item.inventoryOccupied
                item.availableNum = +(item.inventoryNum || 0) - +(item.inventoryOccupied || 0) + ''

                item.inventoryAmount = item.totalAmount
                item.orgName = item.supplierName
                item.bussCode = item.purchaseCode
            }
        })
        setDataSource(records)
        let _total = await service.total(searchParams)
        setTableTotal(_total.retData)
        setLoading(false)
    }

    //设置搜索value
    const updateSearchParam = (updateParam: ISearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({})
        orders.current = []
        loadData(initPgCfg, {})
    }

    const [columnsSetVisible, setColumnsSetVisible] = useState<boolean>(false)

    //导出
    const exportData = async (searchValue: ISearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: '快速导出中' })
        try {
            const searchParams = {
                shopId,
                branch,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
                orders: orders.current
            }
            if (branch === 'ci') {
                searchParams.searchItem = {
                    shopId,
                    branch,
                    ...searchValue
                }
            }
            let result = await service.export(searchParams)
            result && setLoadingInfo({ loadingVisible: false, txt: '' })
            if (branch === 'ci') {
                message.success('导出进行中。请稍后去导出记录列表查看')
            } else {
                convertRes2Blob(result)
            }
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: '' })
        }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        pgCfg,
        columnsSetVisible,
        loading,
        orders,
        tableTotal,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setColumnsSetVisible,
        exportData
    }
})
