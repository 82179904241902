import React, { useState, useEffect } from 'react'
import { YHModal } from '@/components/YHModal'
import { formatNum } from '@/utils/utils'
import { occupyService } from './occupyService'
import Table from 'antd/lib/table'
import useGlobalModel from '@/model/globalModel'
import { useDictModel } from '@/views/base/dict/dictModel'

//占用库存数
export const OccupyNumModal = ({ visible, setVisible, occupyInfo }) => {
    const { getTreeNodeName } = useDictModel(() => [])
    const { user: { branch } } = useGlobalModel()

    const [dataSource, setDataSource] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [columns, setColumns] = useState<any[]>([])

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const onCancel = () => {
        setVisible(false)
    }

    const loadData = async () => {
        if (!visible) {
            return false
        }
        setLoading(true)
        try {
            const searchParams = {
                branch: branch,
                shopId: occupyInfo.shopId,
                materialCode: occupyInfo.materialCode
            }

            if (branch === 'scm') {
                let { retData } = await occupyService.scmOccupy({
                    ...searchParams,
                    types: ['occupy_ssrd', 'occupy_sprd', 'occupy_ssd', 'occupy_std']
                })
                setDataSource(retData ?? [])
                setColumns(defaultColumns)
            } else if (branch === 'bcm') {
                let { retData } = await occupyService.bcmOccupy({ ...searchParams, pageSize: 10000, pageNum: 1 })
                let { records } = retData
                setDataSource(records ?? [])

                let cols = defaultColumns.filter(e => e.title !== '金额')
                cols = [...cols, ...[
                    { title: '单据类型 ', width: 80, align: 'center', dataIndex: 'type' },
                    {
                        title: '单据状态 ',
                        width: 150,
                        align: 'center',
                        dataIndex: 'status',
                        render: text => getTreeNodeName(text) ?? ''
                    },
                    { title: '客户名称 ', width: 200, align: 'center', dataIndex: 'customerName' }
                ]]
                setColumns(cols)
            } else if (branch === 'vrm') {
                let { retData } = await occupyService.vrmOccupy({
                    ...searchParams,
                    'types': ['occupy_vam', 'occupy_vmm', 'occupy_ssd', 'occupy_prd']
                })
                setDataSource(retData ?? [])
                setColumns(defaultColumns)
            } else if (branch === 'ci') {
                let { retData: org } = await occupyService.getByShopId(occupyInfo.shopId)
                let { retData } = await occupyService.ciOccupy({
                    ...searchParams,
                    'organId': org.id,
                    'pageSize': 10000,
                    'pageNum': 1,
                    'types': ['occupy_ssd', 'occupy_omm', 'occupy_morm', 'occupy_mom', 'occupy_prd']
                })
                setDataSource(retData ?? [])
                setColumns([...defaultColumns, ...[{
                    title: '主修人',
                    width: 66,
                    align: 'center',
                    dataIndex: 'major'
                }]])
            }
            setLoading(false)
        } catch {
            setLoading(false)
            setDataSource([])
        }
    }

    const defaultColumns: any[] = [
        {
            title: '序号',
            width: 66,
            align: 'center',
            render: (t: any, r: any, i: number) => i + 1
        },
        {
            title: '业务日期',
            width: 180,
            align: 'center',
            dataIndex: 'createTime'
        },
        {
            title: '单据编号',
            width: 120,
            align: 'center',
            dataIndex: 'code'
        },
        {
            title: '仓库',
            width: 120,
            align: 'center',
            dataIndex: 'warehouseName'
        },
        {
            title: '单价',
            width: 160,
            align: 'center',
            dataIndex: 'price',
            render: text => `￥${formatNum(text)}`
        },
        {
            title: '数量',
            width: 140,
            align: 'center',
            dataIndex: 'num'
        },
        {
            title: '金额',
            width: 120,
            align: 'center',
            render: (text, record) => `￥${formatNum(record.num * record.price)}`
        }
    ]

    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title={<p style={{ margin: 10 }}><span>占用数量:{occupyInfo.occupyNum}</span><span
            className="ml-20">商品编号:{occupyInfo.materialCode}</span><span
            className="ml-20">商品名称:{occupyInfo.materialName}</span></p>}
        width={1300}
        bodyHeight={550}
    >
        <Table
            loading={loading}
            rowKey={record => record.id}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 'max-content', y: 340 }}
        />
    </YHModal>
}
