import React from "react";
import "./modal.less";

type IFooterButtons = {
    text: string,
    style?: React.CSSProperties,
    onClick?: () => void
}

interface IModalProps {
    visible: boolean,
    title?: string | JSX.Element,
    closable?: boolean,
    mask?: boolean,
    maskClosable?: boolean,
    width?: number | string,
    bodyHeight?: number
    children?: React.ReactNode,
    showFooter?: boolean,
    okText?: string
    onOk?: () => void,
    cancelText?: string
    onCancel?: () => void,
    footerButs?: IFooterButtons[]
}

export const YHModal = ({
    visible,
    title = "标题",
    onCancel = () => null,
    maskClosable = true,
    mask = true,
    width = 650,
    bodyHeight = 300,
    closable = true,
    children = null,
    showFooter = false,
    okText = "确定",
    onOk,
    cancelText = "取消",
    footerButs = []

}: IModalProps): JSX.Element => {



    const handleClose = () => {
        onCancel?.();
    }

    return <div className="modal" style={{ width: visible ? "100%" : "0%" }}>
        <div
            className={["modal-mask-warp", (mask && visible) ? 'modal-mask-show' : "modal-mask-hide"].join(' ')}
            onClick={() => maskClosable && handleClose()}>
        </div>
        {visible ?
            <div className="modal-main" style={{ width: typeof width === "string" ? width : width + 'px', height: '80%' }}>
                <div className="modal-head">
                    <span>{title}</span>
                    {closable && <span className="close-btn" onClick={handleClose}>X</span>}
                </div>
                <div className="modal-body">
                    {
                        children
                    }
                </div>
                <div className="modal-footer" hidden={!showFooter}>
                    {footerButs && footerButs.map((item, index) => (<span key={index} style={item.style} onClick={item.onClick}>{item.text}</span>))}
                    <span onClick={() => {onOk?.()}} className="submit-button">{okText}</span>
                    <span onClick={handleClose} >{cancelText}</span>
                </div>
            </div>
            : null}
    </div>
}